import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticTableOfContents } from "../queries";
import { FaAmazon, FaBookOpen, FaBook, FaEnvelope, FaHome, FaTelegram, FaTwitter, FaYoutube } from "react-icons/fa";
import Seo from "../components/Seo";
import { useMash } from "../mash/Mash";
import { useSizeDocumentBody } from "../hooks/useSizeDocumentBody";
import './index.css';

function BookIndex() {
  const { ready } = useMash();

  const toc = useStaticTableOfContents();

  useSizeDocumentBody();

  return (
    <>
      <Seo />
      <Helmet>
        <script async src="https://components.getmash.com/donate/donate-btn.js"></script>
      </Helmet>
      <div className="page overflow-hidden flex flex-col h-full" style={{ visibility: !ready ? "hidden" : "visible" }}>
        <div
          className="navbar header py-4 px-8 border font-bold text-sm sm:text-base flex items-center justify-evenly text-center"
          style={{ minHeight: 80 }}
        >
          {toc.title.toLocaleUpperCase()}
        </div>

        <div className="overflow-auto pt-8 px-8">
          <div className="grid-container grid grid-cols-1 sm:grid-cols-normal h-full w-full max-w-6xl m-auto">
            <div className="grid-left-col flex flex-col items-center gap-6 mb-8 sm:mb-0">
              <div className="img-wrapper">
                <StaticImage src="../images/e21m_mockup.png" alt="Bitcoin: everything divided by 21 million" height={350} />
              </div>

              <div className="buttons-wrapper flex flex-col gap-4 w-full justify-center items-center">
              <Link
                  className="button flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  to={toc.chapters[0].link || toc.chapters[0].sections[0].link}
                >
                  <FaBookOpen />
                  Read Now
                </Link>
                <a
                  className="button flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  href="https://konsensus.network/product/bitcoin-everything-divided-by-21-million/"
                  target="_blank"
                >
                  <FaBook />
                  Buy Paperback
                </a>
                <a
                  className="button flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  href="https://www.amazon.com/Bitcoin-Everything-divided-21-million/dp/9916697191/"
                  target="_blank"
                >
                  <FaAmazon />
                  Buy on Amazon
                </a>
                {/* <mash-donate-btn class="w-full max-w-xs" handle={mashConfig.earnerID} size="sm" /> */}
              </div>
              <div className="flex mt-10 items-center justify-evenly w-full max-w-xs">
                <a
                  className="icons flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://twitter.com/konsensusn"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaTwitter />
                </a>

                <a
                  className="icons flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://www.youtube.com/c/KonsensusNetwork"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaYoutube />
                </a>

                <a
                  className="icons flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://t.me/konsensusnetwork"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaTelegram />
                </a>
              </div>
              <div className="w-full border max-w-xs rounded-full" />

              <div className="flex items-center justify-evenly w-full max-w-xs">
                <div className="desc mb-8 items-center justify-evenly text-justify">
                <h2 className="text-center mb-10">About The Author</h2>
                <div className="img-wrapper mb-8">
                <StaticImage src="../images/knut_tp.png" alt="Mash" height={350} />
                </div>
                <p>
                Knut's books are best described as guided meditation that soothe the experienced and tutor the uninitiated in the ways of bitcoin.</p>
                <p>
                Knut used to have a fiat life as a ship captain and now you may choose him as the captain of your bitcoin space ark to navigate the stormy seas of fiat imposition and swells of cognitive dissonance. Grounded in logic and unbound in spirit, Knut offers something for everyone.</p>
                <p>
                We have plenty of pessimism and outright nihilism in the bitcoin scene and Knut, using his quick wit and humor, whips up a fresh gust of positivity and optimism to disperse the clouds of misery, letting the rays of infinite optionality, prosperity and love shine through.</p>
                </div>
              </div>
              <div className="flex items-center justify-evenly w-full max-w-xs">
                <a
                  className="icons flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://twitter.com/knutsvanholm"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaTwitter />
                </a>

                <a
                  className="icons flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="mailto:info@knutsvanholm.com"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaEnvelope />
                </a>

                <a
                  className="icons flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://www.knutsvanholm.com/"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaHome />
                </a>
              </div>
              <div className="w-full border max-w-xs rounded-full" />
              <div className="img-wrapper mt-16">
                <StaticImage src="../images/infinityday.jpeg" alt="Mash" height={350} />
              </div>
              <div className="buttons-wrapper flex flex-col gap-4 w-full justify-center items-center mb-10">
                <a
                  className="button flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  href="https://konsensus.network/"
                  target="_blank"
                >
                  <FaBook />
                  Visit Shop
                </a>
                </div>
            </div>
            <div className="grid-right-col px-0 sm:px-8">
              <div className="desc mb-8 text-justify">
                <h2 className="mt-0 text-center">Description</h2>
                <p>
                We're all chained to the unforgiving arrow of time. We're born, we live, and we get old. At least that's what we hope will happen. Then we die.
                </p>
                <p>
                None of us humans are millionaires in terms of hours, yet we sacrifice our most valuable and scarce asset just to stay alive in the hamster’s wheel one more day.
                </p>
                <p>
                In pursuit of saving time, Satoshi stumbled upon a discovery of a new, weightless, absolutely scarce element. The showstopper for each and every time thief. Element zero. Bitcoin.
                </p>
                <p>
                Bitcoin’s limited supply cap is the immovable object that enables us all to become an unstoppable force.
                </p>
              </div>
              <div className="border my-8" />
              <div className="toc">
                {toc.chapters.map(chapter => (
                  <div key={chapter.title} className="chapter-wrapper items-center justify-evenly text-center">
                    <div className="chapter mb-4">
                      <Link
                        to={chapter.link || chapter.sections[0].link}
                        className="mb-4 text-lg font-bold hover:underline"
                      >
                        {chapter.sections.length === 0 ? "" : `Chapter ${chapter.number}`}
                      </Link>
                    </div>
                    {chapter.sections.length > 0 && (
                      <ol className="sections border rounded-lg divide-y list-none p-0 ml-0">
                        {chapter.sections.map(section => (
                          <Link
                            key={section.link}
                            to={section.link}
                            className="block hover:bg-slate-100 active:bg-slate-200 text-black"
                          >
                            <li className="section py-4 px-8 mb-0">
                              {section.title}
                            </li>
                          </Link>
                        ))}
                      </ol>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookIndex;
